import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import Helmet from 'react-helmet';
import logo from '../images/logo.png';

const Layout = ({
  children,
}) => {
  return (
    <div className="flex flex-col h-full">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
      </Helmet>
      <header className="bg-blue-900 p-8">
        <div className="max-w-screen-xl mx-auto">
          <Link to="/">
            <img src={logo} className="max-h-12" alt="The Clinician logo" />
          </Link>
        </div>
      </header>
      <main className="max-w-screen-lg mx-auto w-full flex-1 p-6">
        {children}
      </main>
      <footer className="px-8 py-4">
        <div className="max-w-screen-xl mx-auto flex justify-between">
          <span className="text-sm">© 2021</span>
          <a className="text-sm underline" href="mailto:support@zedoc.io">support@zedoc.io</a>
        </div>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
